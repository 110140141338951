.about {
  width: 100%;
  padding: 20px 0;
  .container {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    padding-top: 40px;
    @media (max-width: 820px) {
      flex-flow: column;
      padding-bottom: 0;
      align-items: center;
      h2 {
        margin: 0;
        padding-bottom: 30px;
      }
      img {
        // padding: 15px 0;
        width: 400px;
        border-radius: 10px;
      }
      p{
        font-size: 15px;
        padding-top: 15px;
      }
      .bottom{
        padding-top: 30px;
      }
    }
    @media (max-width: 600px) {
      img{
        width: 100%;
      }
    }
    .shade {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-width: 350px;
        border-radius: 10px;
      }
    }
    .desk {
      width: 55%;
      // padding-top: 10px;
      // padding-left: 40px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      // justify-content: center;
      p {
        width: 95%;
        margin-top: 0;
        padding: 40px 0 30px;
        text-align: start;
      }
      h2 {
        img {
          width: 70px;
        }
      }
      .bottom {
        width: 100%;
        display: flex;
        padding-top: 15px;
        // padding: 30px 0 20px 0;
      }
    }
  }
  @media (min-width: 1400px) {
    .container {
      width: 1185px !important;
    }
  }
}
