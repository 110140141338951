.foot {
  width: 100%;
  padding: 50px 0;
  .bottomContainer {
    width: 85%;
    margin: auto;
    @media(min-width:1440px){
      width: 1185px !important;
    }
    .slick-slider {
      .slickPrev{
        right: auto;
        left: -20px;
        top: 48%;
      }
      .slickNext{
        right: -20px;
        top: 48%;
        // left: -15px;
      }
      .slick-track{
        display: flex;
        align-items: flex-end;
        @media(max-width:560px){
          align-items: center;
        }
      }
      .slick-slide {
        // padding: 0 5px;
        width: max-content;
        .imag {
          width: 96% !important;
          margin: 0 auto;
          img {
            width: 100%;
            padding: 0 !important;
          }
        }
        // img{
        //   padding: 0 15px;
        // }
      }
    }
    .bottomHeading {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      h1 {
        margin: 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 37px;
        text-transform: uppercase;
        color: #092f57;
      }
      img {
        width: auto;
        height: 1px;
        margin-left: 15px;
      }
      @media(max-width:840px){
        img{
          display: none;
        }
      }
      @media(max-width:350px){
        h1{
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
    .slider {
      width: 98%;
      display: flex;
      .imgOverflow {
        overflow-x: scroll;
        display: flex;
        margin: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        img {
          margin: 15px;
          width: 400px;
          height: auto;
        }
      }
    }
  }
}
