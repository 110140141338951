.footer {
  width: 100%;
  background-color: #ebebeb;
  .footerContainer {
    width: 93%;
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 10px;
    @media(min-width:1440px){
      width: 1185px !important;
    }
    .footerLinks {
      width: 90%;
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
      padding-bottom: 30px;
      a,p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-transform: uppercase;
        color: #092f57;
        text-decoration: none;
      }
    }
    img {
      width: 100%;
      height: 1px;
    }
    .footerIcons {
      width: 20%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      padding-bottom: 15px;
      img {
        width: auto;
        height: auto;
      }
    }
    .footerCopy {
      img {
        width: auto;
        height: auto;
      }
      p {
        font-size: 13px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        color: #092f57;
        padding-bottom: 10px;
        a {
          text-decoration: underline;
          color: #092f57;
        }
      }
    }
    @media (max-width: 1000px) {
      .footerLinks {
        flex-flow: column;
        padding: 15px 0;
        justify-content: center;
        align-items: center;
        a,p {
          padding: 10px 0;
        }
      }
      .footerIcons{
        width: 300px;
        padding-bottom: 25px;
      }
    }
    @media (max-width: 400px) {
      .footerIcons{
        width: 80%;
      }
    }
  }
}
