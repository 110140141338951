.contact {
  // padding: 85px 0 95px;
  position: relative;
  #contact{
    position: absolute;
    top: -65px;
    width: 70px;
    height: 70px;
  }
  .container {
    padding: 40px 0;
    p {
      margin: 20px auto;
      color: #092f57;
      width: 80%;
    }
    .contactDetails {
      width: 88%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 40px;
      margin: 0 auto;
      a,.inner {
        width: 310px;
        display: block;
        padding: 0 20px;
        &:last-child {
          p {
            text-transform: capitalize;
          }
        }
        img {
          width: auto;
        }
        p {
          font-size: 20px;
          line-height: 24px;
          text-transform: lowercase;
          color: #092f57;
          margin: 0;
          width: 100%;
          padding-top: 20px;
        }
      }
    }
    @media (max-width: 1000px) {
      h2{
        img{
          display: none;
        }
      }
      p{
        display: none;
      }
      .contactDetails {
        flex-flow: column;
        padding-top: 25px;
        a,.inner{
          width: 100%;
          padding: 15px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p{
            width: 80%;
            text-align: start;
            display: block;
            padding: 0;
            word-break: break-word;
          }
          img{
            width: 50px;
          }
        }
      }
    }
  }
}
