@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .load {
    width: 100%;
    height: calc(100vh - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 6px solid #f3f3f3;
      border-top: 6px solid #092f57;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: spin 2s ease infinite;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .container {
    width: 92%;
    margin: 0 auto;
    @media (min-width: 1440px) {
      width: 1185px !important;
    }
  }

  .error {
    width: 100%;
    height: calc(100vh - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    h1 {
      text-transform: uppercase;
      margin: 0 0 12px 0;
    }
    p {
      padding: 0px 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        outline: none;
        border: none;
        text-decoration: underline;
        text-decoration-color: #092f57;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #092f57;
        background-color: transparent;
        cursor: pointer;
      }
      a {
        padding-left: 6px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #092f57;
        text-decoration: underline;
        text-decoration-color: #092f57;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  button,
  a {
    font-family: "Montserrat", sans-serif;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #092f57;
    margin: 0;
  }

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    color: #092f57;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 10px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #7b7f8c;
    margin: 0;
  }

  .btn {
    padding: 10px 22px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #092f57;
    border-radius: 5px;
    border: 1px solid #092f57;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: transparent;
      color: #092f57;
    }
  }

  .slider {
    display: block;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .slide {
      display: flex;
      width: 1800px;
    }
  }

  .slickPrev {
    position: absolute;
    display: block !important;
    width: 18px;
    height: 33px;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    // background: transparent;
    right: 45px;
    top: 45%;
    left: auto;
    img {
      width: 18px !important;
      height: 33px !important;
    }
    &.slick-disabled {
      opacity: .3;
      img {
        cursor: not-allowed;
      }
    }
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slickNext {
    position: absolute;
    display: block !important;
    width: 18px;
    height: 33px;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    // background: transparent;
    right: 0;
    top: 45%;
    img {
      width: 18px !important;
      height: 33px !important;
    }
    &.slick-disabled {
      opacity: .3;
      img {
        cursor: not-allowed;
        // filter: brightness(0) saturate(100%) invert(80%) sepia(29%)
        //   saturate(119%) hue-rotate(161deg) brightness(107%) contrast(104%);
      }
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-text-fill-color: #333;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #333;
  }
}
.textInput {
  position: relative;
  padding: 0;
  display: block;
  &:hover {
    label {
      color: #708ca0;
    }
    .input {
      border: 1px solid #7d7c81;
    }
    textarea.textfield {
      border: 1px solid #7d7c81;
    }
  }
  .error-text {
    text-align: start;
    color: red !important;
    font-size: 12px !important;
    line-height: 14px !important;
    margin: 0 !important;
    padding-bottom: 5px;
    padding-left: 5px;
  }
  label {
    position: absolute;
    top: 19px;
    left: 15px;
    padding: 0;
    color: #c4c4c4;
    pointer-events: none;
    transition: 0.5s;
    z-index: 10;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 18px;
    span {
      color: #092f57;
    }
  }
  .input {
    width: 100%;
    height: 40px;
    border: 1px solid #e5e5e5;
    outline: none;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 8px 0;
    padding: 0 15px;
    font-size: 16px;
    line-height: 18px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 200;
    color: #333;
    outline: none;
    transition: all 1s ease;

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: #c4c4c4;
      font-size: 14px;
    }
    // &:not([value=""]) {
    //   border-color: #ffdd28;
    //   color: #ffdd28 !important;
    // }

    &:focus ~ label,
    &:not([value=""]) ~ label {
      top: 2px !important;
      left: 8px !important;
      font-size: 12px;
      line-height: 14px;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      height: none;
    }
    &:not([value=""]) ~ label {
      color: #333;
    }
    &:not([value=""]) {
      border: 1px solid #7d7c81;
    }

    &:focus ~ label {
      color: #092f57;
    }

    &:focus,
    &:valid ~ label,
    &:not([value=""]) ~ label {
      outline: none;
      border-color: #092f57;
      box-shadow: none;
    }
  }

  textarea.textfield {
    height: 150px;
    width: 100%;
    outline: none;
    border: 1px solid #e5e5e5;
    outline: none;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 8px 0;
    padding: 15px;
    font-size: 16px;
    line-height: 18px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 200;
    color: #333;
    resize: none;
    transition: all 1s ease;

    &:focus ~ label {
      color: #092f57 !important;
    }

    &:focus ~ label {
      color: #092f57;
      top: 2px;
      left: 8px;
      font-size: 12px;
      line-height: 14px;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      height: none;
    }

    &:focus,
    &:valid ~ label,
    &:not([value=""]) ~ label {
      outline: none;
      border-color: #092f57;
      box-shadow: none;
    }

    &.has-value {
      border-color: #7d7c81;
      // color: #333 !important;
    }
    &.has-value:focus {
      border-color: #092f57;
      color: #667c8a !important;
    }

    &.has-value ~ label {
      top: 2px;
      left: 8px;
      font-size: 12px;
      line-height: 14px;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      // color: #919eab;
      outline: none;
      border-color: #7d7c81;
      box-shadow: none;
      color: #333;
    }
    &.has-value:focus ~ label {
      color: #092f57;
    }
  }
  &.errorInput {
    label {
      color: red !important;
    }
    .input {
      border-color: red !important;
    }
  }
}
.MuiSvgIcon-root {
  fill: #092f57 !important;
}

.popup {
  // max-width: 700px;
  // padding: 35px 65px;
  position: absolute;
  z-index: 800;
  top: 50%;
  left: 45%;
  transform: translate(-45%, -50%);
  background-color: white;
  outline: none !important;
  max-width: 545px;
  // height: 270px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 35px;
  // @media (max-width: 445px) {
  //   padding: 15px;
  // }
  @media (max-width: 565px) {
    padding: 25px !important;
  }
  @media (max-width: 355px) {
    padding: 15px !important;
  }
  .thanks {
    width: 400px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 -10px;
    img {
      position: absolute;
      top: -15px;
      right: -10px;
      cursor: pointer;
    }
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #092f57;
      margin: 0;
      padding-bottom: 15px;
    }
    p {
      text-align: center;
    }
    .bottom {
      padding-top: 20px;
    }
    @media (max-width: 730px) {
      img {
        right: 0;
        top: -22.5px;
      }
    }
    @media (max-width: 565px) {
      img {
        top: -15px;
        right: -10px;
      }
    }
    @media (max-width: 455px) {
      width: 300px;
    }
    @media (max-width: 330px) {
      width: 270px;
    }
  }
  &.offerModal {
    max-width: 660px;
    max-height: 430px;
    padding: 35px 20px;
    .box {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      // margin: 0 -10px;
      .img {
        position: absolute;
        top: -5px;
        right: 0px;
        cursor: pointer;
      }
      .head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 15px;
        img {
          width: 85px;
          height: 65px;
          border: 1px solid #ebebeb;
          box-sizing: border-box;
          border-radius: 5px;
        }
        .detailOffer {
          padding-left: 25px;
          h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-align: start;
            color: #092f57;
            margin: 0;
            padding-bottom: 5px;
          }
          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: #5fbfe2;
          }
        }
      }
      .para {
        overflow: scroll;
        max-height: 250px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        p {
          text-align: start;
        }
      }
      .bottom {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &.active {
          // box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 -11px 5px -5px rgba(0, 0, 0, 0.15);
        }
      }
      @media (max-width: 555px) {
        width: 300px;
      }
      @media (max-width: 330px) {
        width: 270px;
      }
    }
  }
  .modalForm {
    // width: 100% !important;
    width: 400px;
    margin: 0;
    @media (max-width: 565px) {
      width: 300px;
    }
    @media (max-width: 355px) {
      width: 270px !important;
    }
    .head {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-flow: column;
      padding-bottom: 20px;
      img {
        margin-right: -15px;
        margin-top: -15px;
        cursor: pointer;
      }
      .title {
        width: 100%;
        padding-top: 20px;
        h1 {
          color: #092f57;
          margin: 0;
          font-size: 24px;
          font-style: normal;
          text-align: center;
          line-height: 33px;
          white-space: nowrap;
        }
        p {
          color: #989cac;
          font-size: 16px;
          font-style: normal;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-weight: normal;
          line-height: 20px;
        }
      }
    }
    .error-text {
      text-align: start;
      color: red !important;
      font-size: 12px !important;
      line-height: 14px !important;
      margin: 0 !important;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-top: 0;
      margin-top: -5px;
    }
    .textInput {
      margin: 4px 0;
    }
    .internal {
      padding-bottom: 37px;
      width: 170px;
      .css-1rhbuit-multiValue {
        background-color: #eee;
      }
      .css-12jo7m5 {
        font-family: "Montserrat", sans-serif !important;
        color: #667c8a;
        font-size: 14px;
      }
      .dropped {
        width: 240px;
        .MuiInputBase-root {
          font-family: "Montserrat", sans-serif !important;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 17px;
          color: #667c8a;
          background-color: white !important;
          &.MuiInput-underline {
            content: none !important;
            &::after,
            &::before {
              border: none;
            }
          }
          @media (hover: none) {
            .MuiInput-underline:hover:not(.Mui-disabled):before {
              border: none;
            }
          }
          .MuiInput-underline:hover:not(.Mui-disabled):before {
            border: none;
          }
          .MuiSelect-select {
            padding: 12px 24px 12px 14px;
            border: 0.5px solid #637381;
            box-sizing: border-box;
            border-radius: 3px;
            cursor: pointer;
            &:focus {
              background-color: white !important;
            }
          }
        }
      }
      .p {
        width: 100%;
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        margin: 0;
        color: #667c8a;
        cursor: pointer;
        padding: 10px;
        border: 0.5px solid #637381;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        align-items: center;
        .text {
          display: flex;
          align-items: center;
          padding-bottom: 0;
          img {
            width: 15px;
            height: 15px;
          }
        }
        .menu > .item {
          font-weight: normal;
          font-size: 15px;
          line-height: 17px;
          margin: 0;
          color: #667c8a;
          font-family: "Montserrat", sans-serif !important;
          font-style: normal;
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #919eab;
        margin-bottom: 16px;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #667c8a;
        margin: 0;
        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    .select {
      width: 100%;
      position: relative;
      padding: 12px 0;
      label {
        position: absolute;
        top: 23px;
        left: 15px;
        padding: 0;
        color: #c4c4c4;
        pointer-events: none;
        transition: 0.5s;
        z-index: 10;
        margin-bottom: 0;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 16px;
        line-height: 18px;
        span {
          color: red;
        }
        &.labTop {
          top: 6px;
          left: 8px;
          font-size: 12px;
          line-height: 14px;
          background-color: white;
          padding-left: 5px;
          padding-right: 5px;
          // color: #919eab;
          outline: none;
          border-color: #7d7c81;
          box-shadow: none;
          color: #333;
        }
      }
      .MuiInputBase-root {
        width: 100%;
        &.MuiInput-underline::before,
        &.MuiInput-underline::after {
          border-bottom: 0 !important;
        }
        &.selTop {
          .MuiSelect-root {
            border: 1px solid #333 !important;
          }
        }
        .MuiSelect-root {
          width: 100%;
          height: 40px;
          border: 1px solid #e5e5e5 !important;
          outline: none;
          box-sizing: border-box;
          background-color: white !important;
          border-radius: 10px;
          margin: 8px 0;
          display: flex;
          align-items: center;
          margin-top: 0;
          padding: 9px 15px !important;
          font-size: 16px;
          line-height: 18px;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 200;
          color: #333;
          outline: none;
          transition: all 1s ease;
        }
        .MuiSelect-icon {
          top: calc(50% - 16px);
          color: rgba(0, 0, 0, 0.54);
          right: 3px;
        }
        // .MuiSelect-nativeInput{
        //   border-bottom: 0 !important;
        // }
      }
    }
    .date {
      padding: 12px 0;
      padding-bottom: 6px;
      .MuiFormControl-root {
        width: 100%;
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          transform: translate(15px, -10px) scale(0.95);
        }
        .MuiOutlinedInput-input {
          padding: 10.5px 15px;
          font-size: 16px !important;
          line-height: 18px !important;
          font-family: "Montserrat", sans-serif !important;
          font-style: normal !important;
          font-weight: 500 !important;
          color: #333 !important;
        }
        label {
          font-family: "Montserrat", sans-serif !important;
          font-weight: 500 !important;
          color: #333 !important;
          font-size: 12px;
          line-height: 14px;
          background-color: white;
          z-index: 11;
          padding: 3px;
          padding-left: 0;
        }
        .MuiOutlinedInput-notchedOutline {
          border-radius: 10px;
          border: 1px solid #333 !important;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      padding-top: 24px;
      h1 {
        color: #979797;
        margin: 0;
        padding: 15px 0;
        font-size: 18px;
        font-style: normal;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 134.77%;
        text-transform: uppercase;
      }
      p {
        color: #092f57;
        font-size: 20px;
        font-style: normal;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 134.77%;
        a {
          color: #4183c4;
        }
      }
      .btn {
        &:hover {
          background-color: #092f57;
          color: #fff;
        }
        &:disabled {
          opacity: 0.75;
          cursor: not-allowed;
        }
      }
    }
  }
  @media (max-width: 730px) {
    padding: 35px 20px;
    .btn {
      padding: 8px 16px;
    }
  }
}

code {
  font-family: "Montserrat", sans-serif;
}
