.about {
  width: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;
  background-color: white;
  padding-bottom: 20px;
  .aboutContainer {
    width: 93%;
    margin: 0 auto;
    @media(min-width:1440px){
      width: 1185px !important;
    }
    // margin-top: 70px;
    .aboutHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      h1 {
        margin: 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 37px;
        text-transform: uppercase;
        color: #092f57;
      }
    }
    .outerbox {
      width: 88%;
      height: 410px;
      margin: auto;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .innerbox1 {
        width: 48%;
        height: 90%;
        display: flex;
        justify-content: center;
        flex-flow: column wrap;
        align-items: center;
        img{
          margin-top: -10px;
        }
        .boxtitle {
          font-family: "Montserrat";
          font-weight: 800;
          font-size: 30px;
          color: #092f57;
          text-transform: uppercase;
        }
        .boxtext {
          font-size: 18px;
          width: 80%;
          text-align: center;
          color: #7b7f8c;
        }
        // @media(max-width)
        &:nth-child(2) {
          border-left: 0.2px solid #989cac;
        }
      }
    }
    @media (max-width: 800px) {
      margin: 0 auto;
      .outerbox {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        height: auto;
        .innerbox1 {
          width: 100%;
          padding: 40px 0;
          img {
            margin-top: 0 !important;
          }
          h2 {
            font-size: 20px !important;
            padding: 25px 0 16px;
          }
          p {
            font-size: 15px !important;
            margin: 0;
            width: 98% !important;
          }
          &:nth-child(2) {
            border-top: 0.2px solid #989cac;
            border-left: 0;
          }
        }
      }
    }
  }
}
